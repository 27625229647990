import React, { useEffect, useRef } from 'react'
import lottie from 'lottie-web'
import loading from '../../lottie/loading.json'

function Loading() {
    const container = useRef(null)

    useEffect(() => {
        lottie.loadAnimation({
            container: container.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: loading
        });
    }, [])

    return (
        <div style={{ height: '100%', display: 'grid', placeItems: 'center' }} >
            <div
                className='lottie_loading'
                ref={container}
                style={{ maxHeight: 550, height: '100%' }}
            />
        </div>
    )
}

export default Loading
