import React, { useEffect, useState } from "react";
import "./EditMovieSeriesDialog.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  CircularProgress,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import axios from "axios";
// Components
import StandardTextField from "../StandardTextField/StandardTextField";
import CustomVideoPlayer from "../CustomVideoPlayer/CustomVideoPlayer";
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const EditMovieSeriesDialog = ({
  open,
  setOpen,
  data,
  getMovie,
  getWebSeries,
}) => {
  const [state, setState] = useState({
    name: "",
    genre: "",
    shortDesc: "",
    language: "",
    director: "",
    desc: "",
  });
  const [loading, setLoading] = useState(false);
  // Img
  const [uploadImg, setUploadImg] = useState(null);
  const [uploadImgObj, setUploadImgObj] = useState(null);
  const [uploadCount, setUploadCount] = useState(0);
  // Video
  const [uploadVideo, setUploadVideo] = useState(null);
  const [addVideo, setAddVideo] = useState(null);
  // Original
  const [checked, setChecked] = useState(false);
  // Snackbar
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();

  useEffect(() => {
    setState({
      name: data?.name,
      genre: data?.genre,
      shortDesc: data?.short_description,
      language: data?.Language,
      director: data?.Director,
      desc: data?.description,
    });
    setUploadImgObj(data?.image_url);
    setChecked(data?.orignal === 1 ? true : false);
  }, [open]);

  useEffect(() => {
    if (params.id[0] === "m" && data?.mid) {
      getVideoURL();
    }
  }, [Object.keys(data)?.length]);

  const edit_movie = async (data) => {
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/admin/editMovie/`,
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization:
          "Basic " +
          btoa(
            process.env.REACT_APP_USERNAME +
              ":" +
              process.env.REACT_APP_PASSWORD
          ),
      },
    })
      .then((res) => {
        if (res.data.success) {
          setOpen(false);
          return enqueueSnackbar(`Movie Updated Successfully`, {
            variant: "success",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        return enqueueSnackbar(`Error Updating Movie`, {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
        getMovie();
      });
  };

  const onUpdateMovieSeries = async () => {
    if (
      !state.name ||
      !state.genre ||
      !state.shortDesc ||
      !state.language ||
      !state.director ||
      !state.desc
    )
      return enqueueSnackbar(`Please Fill All The Details`, {
        variant: "error",
      });
    if (!uploadImg && !uploadImgObj)
      return enqueueSnackbar(`Image Not Found`, { variant: "error" });
    let formData = new FormData();
    formData.append("name", state.name);
    formData.append("description", state.desc);
    formData.append("Language", state.language);
    formData.append("Director", state.director);
    formData.append("short_description", state.shortDesc);
    formData.append("Genre", state.genre);
    formData.append("orignal", checked ? 1 : 0);

    if (params.id[0] === "m") {
      // Updating Movie //
      // Image
      if (uploadImgObj?.slice(0, 4) === "blob") {
        if (!uploadImg)
          return enqueueSnackbar(`Image Not Found`, { variant: "error" });
        formData.append("image", uploadImg);
      } else {
        formData.delete("image");
      }
      // Video
      formData.append("mid", data?.mid);
      formData.delete("wsid");
      if (addVideo) {
        formData.delete("q1080p");
        const video_filename = addVideo.name.replace(" ", "_");
        await axios
          .get(
            `${process.env.REACT_APP_BASEURL}/admin/aws_url/${video_filename}`,
            {
              headers: {
                Authorization:
                  "Basic " +
                  btoa(
                    process.env.REACT_APP_USERNAME +
                      ":" +
                      process.env.REACT_APP_PASSWORD
                  ),
              },
            }
          )
          .then(
            async (res) =>
              await axios({
                url: res.data.url,
                method: "PUT",
                data: addVideo,
                headers: {
                  "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (data) => {
                  setUploadCount(Math.round((100 * data.loaded) / data.total));
                },
              }).then(async (data) => {
                formData.append("q1080p", video_filename);
                await edit_movie(data);
              })
          );
      } else {
        await edit_movie(formData);
      }
      setLoading(true);
    } else if (params.id[0] === "w") {
      // Updating Web Series //
      formData.delete("q1080p");
      formData.append("wsid", data?.wsid);
      if (uploadImgObj?.slice(0, 4) === "blob") {
        if (!uploadImg)
          return enqueueSnackbar(`Image Not Found`, { variant: "error" });
        formData.append("image", uploadImg);
      } else {
        formData.delete("image");
      }

      setLoading(true);
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASEURL}/admin/edit_Web_series/`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization:
            "Basic " +
            btoa(
              process.env.REACT_APP_USERNAME +
                ":" +
                process.env.REACT_APP_PASSWORD
            ),
        },
      })
        .then((res) => {
          if (res.data.success) {
            console.log(res.data);
            setOpen(false);
            return enqueueSnackbar(`Web Series Updated Successfully`, {
              variant: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          return enqueueSnackbar(`Error Updating Web Series`, {
            variant: "error",
          });
        })
        .finally(() => {
          getWebSeries();
        });
    }
  };

  const getVideoURL = async () => {
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/admin/getLink/${data?.mid}`,
      headers: {
        Authorization:
          "Basic " +
          btoa(
            process.env.REACT_APP_USERNAME +
              ":" +
              process.env.REACT_APP_PASSWORD
          ),
      },
    })
      .then((res) => {
        if (res.data.success) setUploadVideo(res.data.link);
      })
      .catch((err) => {
        console.log(err);
        setUploadVideo(null);
      });
  };

  const onAddImg = (e) => {
    setUploadImg(e.target.files[0]);
    setUploadImgObj(URL.createObjectURL(e.target.files[0]));
  };
  const onDeleteImg = (e) => {
    e.stopPropagation();
    setUploadImg(null);
    setUploadImgObj(null);
  };
  const upload = (id) => document.getElementById(id).click();
  const onChangeText = (e, name) =>
    setState({ ...state, [name]: e.target.value });
  const onAddVideo = (e) => setAddVideo(e.target.files[0]);
  return (
    <Dialog fullWidth={true} maxWidth="lg" open={open} scroll="paper">
      <DialogTitle className="editDialog_header">
        Edit {params.id[0] === "m" ? "Movie" : "Web Series"}
      </DialogTitle>
      <DialogContent dividers={true}>
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: 20 }}
        >
          <div>
            {uploadImgObj ? (
              <div
                onClick={() => upload("editDialog_uploadFile")}
                className="editDialog_uploadImg"
                style={{
                  background: `url(${uploadImgObj}) no-repeat center/cover`,
                }}
              >
                <div>
                  {" "}
                  <p>Change Image</p>{" "}
                </div>
                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={onDeleteImg}
                  className="editDialog_ImgDelBtn"
                />
              </div>
            ) : (
              <button
                className="editDialog_uploadBtn"
                disabled={loading}
                onClick={() => upload("editDialog_uploadFile")}
              >
                Add Image
              </button>
            )}
            <input
              type="file"
              id="editDialog_uploadFile"
              accept="image/png, image/jpeg"
              onChange={onAddImg}
              style={{ display: "none" }}
            />
          </div>
          <div style={{ width: "100%" }}>
            <StandardTextField
              val={state.name}
              onChange={(e) => onChangeText(e, "name")}
              placeholder="Enter Movie Name"
            />
            <StandardTextField
              val={state.genre}
              onChange={(e) => onChangeText(e, "genre")}
              placeholder="Genre"
            />
          </div>
        </div>

        <StandardTextField
          val={state.shortDesc}
          onChange={(e) => onChangeText(e, "shortDesc")}
          placeholder="Year Release |  Web Series/Movie  | Duration  |  Maturity Rating"
        />

        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: 10, width: "100%" }}>
            <StandardTextField
              val={state.language}
              onChange={(e) => onChangeText(e, "language")}
              placeholder="Language"
            />
          </div>
          <div style={{ marginLeft: 10, width: "100%" }}>
            <StandardTextField
              val={state.director}
              onChange={(e) => onChangeText(e, "director")}
              placeholder="Director"
            />
          </div>
        </div>

        <StandardTextField
          val={state.desc}
          onChange={(e) => onChangeText(e, "desc")}
          placeholder="Director"
          rows="5"
          textArea
        />

        <FormControlLabel
          className="editDialog_switch"
          control={
            <Switch
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
            />
          }
          label="Original"
        />

        {params.id[0] === "m" ? (
          <>
            {uploadVideo && (
              <CustomVideoPlayer
                previewImg={data?.image_url}
                url={uploadVideo}
              />
            )}
            <div>
              <Button
                onClick={() => upload("editDialog_uploadFileVideo")}
                disabled={loading}
                className="editDialog_videoUploadBtn"
              >
                {uploadVideo
                  ? "Change Video"
                  : addVideo
                  ? `${addVideo?.name} Added`
                  : "Add Video"}
              </Button>
              <input
                type="file"
                id="editDialog_uploadFileVideo"
                accept="video/*"
                onChange={onAddVideo}
                style={{ display: "none" }}
              />
            </div>
          </>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} color="error" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          disabled={loading}
          color="success"
          onClick={onUpdateMovieSeries}
        >
          {loading ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <CircularProgress size={20} color="success" />
              <p style={{ fontSize: 18, color: "#000", marginLeft: 7 }}>
                {uploadCount} %
              </p>
            </div>
          ) : (
            "Update"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditMovieSeriesDialog;
