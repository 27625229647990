import React, { useEffect, useState } from "react";
import "./MovieSeriesInfo.css";
import { useParams, useNavigate } from "react-router-dom";
import { auth } from "../../components/impExports";
import {
  MenuItem,
  Select,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
} from "@mui/material";
import axios from "axios";
import Loading from "../../components/Loading/Loading";
import CustomVideoPlayer from "../../components/CustomVideoPlayer/CustomVideoPlayer";
import EditMovieSeriesDialog from "../../components/EditMovieSeriesDialog/EditMovieSeriesDialog";
import { useSnackbar } from "notistack";
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faTrash,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";

function MovieSeriesInfo() {
  const navigate = useNavigate();
  const params = useParams();
  const [data, setData] = useState({});
  const [seasonsData, setSeasonsData] = useState([]);
  const [seasons, setSeasons] = useState("");
  const [loading, setLoading] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  // Edit States & Edit Dialog
  const [openEditDialog, setOpenEditDialog] = useState(false);
  // Add seasons
  const [addSeasonNo, setAddSeasonNo] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [editSeason, setEditSeason] = useState(false);
  const [loadingAddSeason, setLoadingAddSeason] = useState(false);
  const [loadingDeleteSeason, setLoadingDeleteSeason] = useState(false);
  // Episode
  const [deleteEpisodeNo, setDeleteEpisodeNo] = useState(null);
  const [loadingDeleteEpisode, setLoadingDeleteEpisode] = useState(true);

  const [loadingUploadEpisode, setLoadingUploadEpisode] = useState(false);
  const [openEditEpisodeDialog, setOpenEditEpisodeDialog] = useState(false);
  const [addEpisodeNo, setAddEpisodeNo] = useState("");
  const [uploadEpisodeImg, setUploadEpisodeImg] = useState(null);
  const [uploadEpisodeImgObj, setUploadEpisodeImgObj] = useState(null);
  const [uploadEpisodeVideo, setUploadEpisodeVideo] = useState(null);
  const [uploadEpisodeVideoObj, setUploadEpisodeVideoObj] = useState(null);
  const [editEpisode, setEditEpisode] = useState(false);
  const [editEpisodeTempData, setEditEpisodeTempData] = useState({
    number: "",
    mid: "",
  });
  const [tLoading, setTLoading] = useState(false);

  // Snackbar
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (params.id[0] === "m") {
      getMovie();
    } else {
      getSeries();
    }
  }, []);

  const getMovie = async () => {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_BASEURL}/admin/getMovie/`,
        { mid: params.id.slice(1) },
        { auth }
      )
      .then((res) => {
        if (res.data.success) {
          setData(res.data.Movie);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "Error Fetching");
        setLoading(false);
      });
  };

  const getSeries = async () => {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_BASEURL}/admin/getWeb_series`,
        { wsid: params.id.slice(1) },
        { auth }
      )
      .then((res) => {
        if (res.data.success) {
          setData(res.data.WebSeries);
          setSeasonsData(res.data.WebSeries.season[0]);
          setSeasons(res.data.WebSeries.season[0]?.name);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "Error Fetching");
        setLoading(false);
      });
  };

  const onSelectChange = (e) => {
    setSeasons(e.target.value);
    setSeasonsData(
      data.season.filter(
        (s) => s.name.toLocaleLowerCase() === e.target.value.toLocaleLowerCase()
      )[0]
    );
  };

  const onDelete = async () => {
    setDelLoading(true);
    if (params.id[0] === "m") {
      await axios
        .post(
          `${process.env.REACT_APP_BASEURL}/admin/deleteMovie/`,
          { mid: params.id.slice(1) },
          { auth }
        )
        .then((res) => {
          if (res.data.success) {
            setDelLoading(false);
            navigate("/allmw");
            return enqueueSnackbar(`Movie Deleted Successfully !!!`, {
              variant: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setDelLoading(false);
          return enqueueSnackbar(`Error Deleting Movie`, { variant: "error" });
        });
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_BASEURL}/admin/delete_Web_series/`,
          { wsid: params.id.slice(1) },
          { auth }
        )
        .then((res) => {
          if (res.data.success) {
            setDelLoading(false);
            navigate("/allmw");
            return enqueueSnackbar(`Web Series Deleted Successfully !!!`, {
              variant: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setDelLoading(false);
          return enqueueSnackbar(`Error Deleting Web Series`, {
            variant: "error",
          });
        });
    }
  };

  const setTrendindMovie = async () => {
    setTLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_BASEURL}/admin/change_trending_movie`,
        {
          mid: data?.mid,
          status: data.trending ? false : true,
        },
        { auth }
      )
      .then((res) => {
        if (res.data.success) {
          getMovie();
        }
      })
      .finally(() => {
        setTLoading(false);
        enqueueSnackbar(
          `${data.name} ${data.trending ? "removed" : "added"} to trending !!!`,
          {
            variant: "success",
          }
        );
      });
  };

  const setTrendindWS = async () => {
    setTLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_BASEURL}/admin/change_trending_ws`,
        {
          wsid: data?.wsid,
          status: data.trending ? false : true,
        },
        { auth }
      )
      .then((res) => {
        if (res.data.success) {
          getSeries();
        }
      })
      .finally(() => {
        setTLoading(false);
        enqueueSnackbar(
          `${data.name} ${data.trending ? "removed" : "added"} to trending !!!`,
          {
            variant: "success",
          }
        );
      });
  };

  // Seasons
  const addMoreSeasons = async () => {
    if (params.id[0] === "w") {
      const seasonNoArr = data?.season?.map((s) => s.name.split(" ")[1]);
      if (seasonNoArr.includes(addSeasonNo))
        return enqueueSnackbar("Season already Exist", { variant: "error" });
      if (!addSeasonNo)
        return enqueueSnackbar("No Input Found", { variant: "error" });
      setLoadingAddSeason(true);

      if (editSeason) {
        // Updating a season
        const body = { name: `Season ${addSeasonNo}`, sid: seasonsData.sid };
        await axios
          .post(`${process.env.REACT_APP_BASEURL}/admin/edit_Season`, body, {
            auth,
          })
          .then((res) => {
            if (res.data.success) {
              setData({
                ...data,
                season: [
                  ...data?.season?.filter((s) => s.sid !== seasonsData.sid),
                  {
                    name: `Season ${addSeasonNo}`,
                    sid: seasonsData.sid,
                    episodes: seasonsData.episodes,
                  },
                ],
              });
              setOpenDialog(false);
              setLoadingAddSeason(false);
              setSeasons(`Season ${addSeasonNo}`);
              setSeasonsData({
                name: `Season ${addSeasonNo}`,
                sid: seasonsData.sid,
                episodes: seasonsData.episodes,
              });
              setAddSeasonNo("");
              return enqueueSnackbar("Updated Successfully !!!", {
                variant: "success",
              });
            } else {
              setLoadingAddSeason(false);
              setAddSeasonNo("");
              return enqueueSnackbar("Something Went Wrong", {
                variant: "error",
              });
            }
          })
          .catch((err) => {
            console.log(err, "Err updating Season");
            setLoadingAddSeason(false);
            setOpenDialog(false);
            setAddSeasonNo("");
            enqueueSnackbar(`Error Updating Season ${addSeasonNo}`, {
              variant: "error",
            });
          });
      } else {
        // Adding a season
        const body = {
          name: `Season ${addSeasonNo}`,
          wsid: params.id.slice(1),
        };
        await axios
          .post(`${process.env.REACT_APP_BASEURL}/admin/add_Season/`, body, {
            auth,
          })
          .then((res) => {
            if (res.data.success) {
              if (data?.season?.length === 0) {
                setSeasons(`Season ${addSeasonNo}`);
                setSeasonsData({
                  name: `Season ${addSeasonNo}`,
                  sid: res.data.sid,
                  episodes: [],
                });
              }
              setData({
                ...data,
                season: [
                  ...data?.season,
                  {
                    name: `Season ${addSeasonNo}`,
                    sid: res.data.sid,
                    episodes: [],
                  },
                ],
              });
              setOpenDialog(false);
              setLoadingAddSeason(false);
              setAddSeasonNo("");
              return enqueueSnackbar(
                `Season ${addSeasonNo} Added Successfully !!!`,
                { variant: "success" }
              );
            } else {
              setLoadingAddSeason(false);
              setAddSeasonNo("");
              return enqueueSnackbar("Something Went Wrong", {
                variant: "error",
              });
            }
          })
          .catch((err) => {
            console.log(err, "Err adding Season");
            setLoadingAddSeason(false);
            setOpenDialog(false);
            setAddSeasonNo("");
            enqueueSnackbar(`Error adding Season ${addSeasonNo}`, {
              variant: "error",
            });
          });
      }
    }
  };

  const onDeleteSeasons = async () => {
    if (params.id[0] === "w") {
      const sid = seasonsData?.sid;
      setLoadingDeleteSeason(true);
      await axios
        .post(
          `${process.env.REACT_APP_BASEURL}/admin/delete_Season/`,
          { sid },
          { auth }
        )
        .then((res) => {
          if (res.data.success) {
            setData({
              ...data,
              season: data?.season?.filter((s) => s.sid !== sid),
            });
            setSeasons(data?.season?.filter((s) => s.sid !== sid)?.[0]?.name);
            setSeasonsData(data?.season?.filter((s) => s.sid !== sid)?.[0]);
            setLoadingDeleteSeason(false);
            return enqueueSnackbar(`${seasons} Deleted Successfully !!!`, {
              variant: "success",
            });
          } else {
            setLoadingDeleteSeason(false);
            return enqueueSnackbar("Something Went Wrong", {
              variant: "error",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setLoadingDeleteSeason(false);
          return enqueueSnackbar(`Error deleting Season ${seasons}`, {
            variant: "error",
          });
        });
    }
  };

  const onEditSeasons = async () => {
    setOpenDialog(true);
    setEditSeason(true);
    setAddSeasonNo(seasonsData?.name?.split(" ")[1]);
  };

  // Episodes
  const onEpisodeClick = () => console.log("episode clicked");
  const onEpisodeDelete = async (e, s) => {
    e.stopPropagation();
    if (params.id[0] === "w") {
      const mid = s.mid;
      setLoadingDeleteEpisode(true);
      setDeleteEpisodeNo(s.mid);
      await axios
        .post(
          `${process.env.REACT_APP_BASEURL}/admin/delete_Episode`,
          { mid },
          { auth }
        )
        .then((res) => {
          if (res.data.success) {
            // Updating the setData
            const selectedSeasonObj = data?.season?.filter(
              (s) => s?.sid === seasonsData?.sid
            )?.[0];
            const selectedSeasonObjCopy = {
              ...selectedSeasonObj,
              episodes: selectedSeasonObj?.episodes?.filter(
                (e) => e.mid !== 56
              ),
            };

            setData({
              ...data,
              season: [
                ...data?.season?.filter((s) => s?.sid !== seasonsData?.sid),
                selectedSeasonObjCopy,
              ],
            });
            setSeasonsData({
              ...seasonsData,
              episodes: seasonsData?.episodes?.filter((s) => s.mid !== mid),
            });
            setDeleteEpisodeNo(null);
            setLoadingDeleteEpisode(false);
            return enqueueSnackbar(`${s.name} Deleted Successfully !!!`, {
              variant: "success",
            });
          } else {
            setLoadingDeleteEpisode(false);
            return enqueueSnackbar("Something Went Wrong", {
              variant: "error",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setLoadingDeleteEpisode(false);
          return enqueueSnackbar(`Error deleting ${s.name}`, {
            variant: "error",
          });
        });
    }
  };
  const onAddEpisodeImg = (e) => {
    setUploadEpisodeImg(e.target.files[0]);
    setUploadEpisodeImgObj(URL.createObjectURL(e.target.files[0]));
  };
  const onAddEpisodeVideo = (e) => {
    setUploadEpisodeVideo(e.target.files[0]);
    setUploadEpisodeVideoObj(URL.createObjectURL(e.target.files[0]));
  };

  const onUploadEpisode = async () => {
    const episodeNoArr = seasonsData?.episodes?.map(
      (e) => e.name.split(" ")[1]
    );
    let formData = new FormData();

    if (editEpisode) {
      // Edit Video Upload
      const updatedArr = episodeNoArr.filter(
        (e) => e !== editEpisodeTempData.number
      );
      if (updatedArr?.includes(addEpisodeNo))
        return enqueueSnackbar("Episode already Exist", { variant: "error" });

      setLoadingUploadEpisode(true);
      formData.append("mid", editEpisodeTempData?.mid);
      formData.append("name", `Episode ${addEpisodeNo}`);
      formData.append("sid", seasonsData?.sid);

      if (uploadEpisodeImg) {
        formData.append("image", uploadEpisodeImg);
      } else {
        formData.delete("image");
      }

      if (uploadEpisodeVideo) {
        const video_filename = uploadEpisodeVideo.name.replace(" ", "_");
        await axios
          .get(
            `${process.env.REACT_APP_BASEURL}/admin/aws_url/${video_filename}`,
            {
              headers: {
                Authorization:
                  "Basic " +
                  btoa(
                    process.env.REACT_APP_USERNAME +
                      ":" +
                      process.env.REACT_APP_PASSWORD
                  ),
              },
            }
          )
          .then(
            async (res) =>
              await axios({
                url: res.data.url,
                method: "PUT",
                data: uploadEpisodeVideo,
                headers: {
                  "Content-Type": "multipart/form-data",
                },
                // onUploadProgress: (data) => {
                //   setUploadCount(
                //     Math.round((100 * data.loaded) / data.total)
                //   );
                // },
              }).then(async (data) => {
                formData.append("q1080p", video_filename);
                await axios({
                  method: "post",
                  url: `${process.env.REACT_APP_BASEURL}/admin/edit_Episode`,
                  data: formData,
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization:
                      "Basic " +
                      btoa(
                        process.env.REACT_APP_USERNAME +
                          ":" +
                          process.env.REACT_APP_PASSWORD
                      ),
                  },
                })
                  .then((res) => {
                    if (res.data.success) {
                      setLoadingUploadEpisode(false);
                      resetAllEpisodeValues();
                      return enqueueSnackbar(
                        `Episode Uploaded Successfully in ${seasonsData?.name}`,
                        { variant: "Success" }
                      );
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    setLoadingUploadEpisode(false);
                    return enqueueSnackbar("Error Uploading Episode", {
                      variant: "error",
                    });
                  });
              })
          )
          .finally(() => setLoadingUploadEpisode(false));
      } else {
        formData.delete("q1080p");
        await axios({
          method: "post",
          url: `${process.env.REACT_APP_BASEURL}/admin/edit_Episode`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization:
              "Basic " +
              btoa(
                process.env.REACT_APP_USERNAME +
                  ":" +
                  process.env.REACT_APP_PASSWORD
              ),
          },
        })
          .then((res) => {
            if (res.data.success) {
              setLoadingUploadEpisode(false);
              resetAllEpisodeValues();
              return enqueueSnackbar(
                `Episode Updated Successfully in ${seasonsData?.name}`,
                { variant: "Success" }
              );
            }
          })
          .catch((err) => {
            console.log(err);
            setLoadingUploadEpisode(false);
            return enqueueSnackbar("Error Updating Episode", {
              variant: "error",
            });
          });
      }
    } else {
      // Upload New Video
      if (!addEpisodeNo)
        return enqueueSnackbar(`Please Add Episode Number`, {
          variant: "error",
        });
      if (!uploadEpisodeVideo)
        return enqueueSnackbar(`Video Not Found`, { variant: "error" });
      if (!uploadEpisodeImg)
        return enqueueSnackbar(`Image Not Found`, { variant: "error" });

      if (episodeNoArr?.includes(addEpisodeNo))
        return enqueueSnackbar("Episode already Exist", { variant: "error" });

      setLoadingUploadEpisode(true);
      formData.append("name", `Episode ${addEpisodeNo}`);
      formData.append("sid", seasonsData?.sid);
      formData.append("image", uploadEpisodeImg);

      const video_filename = uploadEpisodeVideo.name.replace(" ", "_");
      await axios
        .get(
          `${process.env.REACT_APP_BASEURL}/admin/aws_url/${video_filename}`,
          {
            headers: {
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then(
          async (res) =>
            await axios({
              url: res.data.url,
              method: "PUT",
              data: uploadEpisodeVideo,
              headers: {
                "Content-Type": "multipart/form-data",
              },
              // onUploadProgress: (data) => {
              //   setUploadCount(
              //     Math.round((100 * data.loaded) / data.total)
              //   );
              // },
            }).then(async (data) => {
              formData.append("q1080p", video_filename);
              await axios({
                method: "post",
                url: `${process.env.REACT_APP_BASEURL}/admin/add_Episode/`,
                data: formData,
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization:
                    "Basic " +
                    btoa(
                      process.env.REACT_APP_USERNAME +
                        ":" +
                        process.env.REACT_APP_PASSWORD
                    ),
                },
              })
                .then((res) => {
                  if (res.data.success) {
                    setLoadingUploadEpisode(false);
                    resetAllEpisodeValues();
                    return enqueueSnackbar(
                      `Episode Uploaded Successfully in ${seasonsData?.name}`,
                      { variant: "Success" }
                    );
                  }
                })
                .catch((err) => {
                  console.log(err);
                  setLoadingUploadEpisode(false);
                  return enqueueSnackbar("Error Uploading Episode", {
                    variant: "error",
                  });
                });
            })
        )
        .finally(() => setLoadingUploadEpisode(false));
    }
  };

  const onEpisodeUpdate = async (e, s) => {
    e.stopPropagation();
    if (params.id[0] === "w") {
      setEditEpisode(true);
      setOpenEditEpisodeDialog(true);
      setAddEpisodeNo(s?.name?.split(" ")[1]);
      // setUploadEpisodeVideoObj('https://www.w3schools.com/html/mov_bbb.mp4');
      setUploadEpisodeVideoObj(s?.file_url);
      setUploadEpisodeImgObj(s?.image_url);
      setUploadEpisodeImg(null);
      setUploadEpisodeVideo(null);
      setEditEpisodeTempData({ number: s?.name?.split(" ")[1], mid: s?.mid });
    }
  };

  const resetAllEpisodeValues = () => {
    setOpenEditEpisodeDialog(false);
    setAddEpisodeNo("");
    setUploadEpisodeVideo(null);
    setUploadEpisodeVideoObj(null);
    setUploadEpisodeImg(null);
    setUploadEpisodeImgObj(null);
    setEditEpisodeTempData({ number: "", mid: "" });
  };
  //
  const upload = (id) => document.getElementById(id).click();
  const uploadDate = `${new Date(data?.date).getDate()}/${
    new Date(data?.date).getMonth() + 1
  }/${new Date(data?.date).getFullYear()}`;
  return (
    <div className="info_cont">
      {loading ? (
        <Loading />
      ) : (
        <>
          <div style={{ position: "relative" }}>
            <FontAwesomeIcon
              icon={faAngleLeft}
              className="info_backIcon"
              onClick={() => navigate(-1)}
            />
            <FontAwesomeIcon
              icon={faPencilAlt}
              className="info_editIcon"
              onClick={() => setOpenEditDialog(true)}
            />
            <img
              className="info_imgBanner"
              src={data?.image_url}
              alt={data?.name}
            />
          </div>
          <EditMovieSeriesDialog
            open={openEditDialog}
            setOpen={setOpenEditDialog}
            data={data}
            getMovie={getMovie}
            getWebSeries={getSeries}
          />

          <div style={{ padding: 20, paddingTop: 5 }}>
            <h2>{data?.name}</h2>
            <h4 className="info_desc">{data?.description}</h4>

            <div className="info_data">
              <div>
                <p>
                  <span>Director:</span> {data?.Director}
                </p>
                <p>
                  <span>Upload Date:</span>
                  {uploadDate}
                </p>
              </div>
              <div>
                <p>
                  <span>Language:</span> {data?.Language}
                </p>
                <p>
                  <span>Genre:</span>
                  {data?.genre}
                </p>
              </div>
            </div>
            <p className="info_shortDesc">{data?.short_description}</p>

            {params.id[0] === "w" ? (
              <div className="info_seasons">
                {data?.season?.length > 0 && (
                  <Select value={seasons} fullWidth onChange={onSelectChange}>
                    {data?.season?.map((s) => (
                      <MenuItem value={s.name} key={s.sid}>
                        {s.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                <div className="info_seasonsAddSelect">
                  <div>
                    <Button
                      style={{ marginTop: 10 }}
                      onClick={() => setOpenEditEpisodeDialog(true)}
                      color="secondary"
                    >
                      + Episodes
                    </Button>

                    <Dialog
                      open={openEditEpisodeDialog}
                      maxWidth="md"
                      fullWidth
                    >
                      <DialogTitle>
                        Add Episode in {seasonsData?.name}
                      </DialogTitle>
                      <div style={{ padding: 20 }}>
                        <div className="info_dialogEpisodeNameCont">
                          <h5>
                            Episode Name: <span>Episode</span>
                          </h5>
                          <input
                            type="number"
                            autoFocus
                            value={addEpisodeNo}
                            onChange={(e) => setAddEpisodeNo(e.target.value)}
                          />
                        </div>

                        {(editEpisode && uploadEpisodeImgObj) ||
                        (!editEpisode && uploadEpisodeImg) ? (
                          <div
                            onClick={() => upload("episode_uploadFile")}
                            className="episode_uploadImg"
                            style={{
                              background: `url(${uploadEpisodeImgObj}) no-repeat center/cover`,
                            }}
                          >
                            <div>
                              {" "}
                              <p>Change Image</p>{" "}
                            </div>
                          </div>
                        ) : (
                          <button
                            className="episode_uploadBtn"
                            disabled={loadingUploadEpisode}
                            onClick={() => upload("episode_uploadFile")}
                          >
                            Add Image
                          </button>
                        )}
                        <input
                          type="file"
                          id="episode_uploadFile"
                          accept="image/png, image/jpeg"
                          onChange={onAddEpisodeImg}
                          style={{ display: "none" }}
                        />

                        <div>
                          {(editEpisode &&
                            uploadEpisodeVideoObj &&
                            uploadEpisodeImgObj) ||
                          (!editEpisode &&
                            uploadEpisodeVideoObj &&
                            uploadEpisodeImgObj &&
                            uploadEpisodeVideo) ? (
                            <CustomVideoPlayer
                              previewImg={uploadEpisodeImgObj}
                              url={uploadEpisodeVideoObj}
                            />
                          ) : null}
                          <Button
                            onClick={() => upload("episode_uploadFileVideo")}
                            disabled={loadingUploadEpisode}
                            className="episode_videoUploadBtn"
                          >
                            {editEpisode
                              ? `${seasonsData?.name} Added`
                              : uploadEpisodeVideo
                              ? `${uploadEpisodeVideo?.name} Added`
                              : "+ Video"}
                          </Button>
                          <input
                            type="file"
                            id="episode_uploadFileVideo"
                            accept="video/mp4"
                            onChange={onAddEpisodeVideo}
                            style={{ display: "none" }}
                          />
                        </div>
                      </div>
                      <DialogActions>
                        <Button
                          color="error"
                          onClick={resetAllEpisodeValues}
                          disabled={loadingUploadEpisode}
                        >
                          Cancel
                        </Button>
                        <Button
                          color="success"
                          onClick={onUploadEpisode}
                          disabled={loadingUploadEpisode}
                        >
                          {loadingUploadEpisode ? (
                            <CircularProgress
                              size={18}
                              style={{ color: "green" }}
                            />
                          ) : (
                            "ADD"
                          )}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>

                  <div>
                    <Button
                      onClick={() => {
                        setOpenDialog(true);
                        setEditSeason(false);
                      }}
                      color="success"
                    >
                      + Seasons
                    </Button>
                    {data?.season?.length > 0 && (
                      <>
                        <IconButton onClick={onEditSeasons}>
                          <FontAwesomeIcon
                            icon={faPencilAlt}
                            className="info_seasonsAddSelect_editBtn"
                          />
                        </IconButton>
                        <IconButton
                          onClick={onDeleteSeasons}
                          disabled={loadingDeleteSeason}
                        >
                          {loadingDeleteSeason ? (
                            <CircularProgress
                              size={15}
                              style={{ color: "#d11a2a" }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faTrash}
                              className="info_seasonsAddSelect_deleteBtn"
                            />
                          )}
                        </IconButton>
                      </>
                    )}
                  </div>
                  <Dialog open={openDialog} maxWidth="sm" fullWidth>
                    <DialogTitle>
                      {editSeason ? "Update" : "Add a New"} Season
                    </DialogTitle>
                    <div className="info_dialogSeasonNameCont">
                      <h5>
                        Season Name: <span>Season</span>
                      </h5>
                      <input
                        type="number"
                        autoFocus
                        value={addSeasonNo}
                        onChange={(e) => setAddSeasonNo(e.target.value)}
                      />
                    </div>
                    <DialogActions>
                      <Button
                        color="error"
                        onClick={() => {
                          setOpenDialog(false);
                          setAddSeasonNo("");
                        }}
                        disabled={loadingAddSeason}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="success"
                        onClick={addMoreSeasons}
                        disabled={loadingAddSeason}
                      >
                        {loadingAddSeason ? (
                          <CircularProgress
                            size={18}
                            style={{ color: "green" }}
                          />
                        ) : (
                          "ADD"
                        )}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
                {data?.season?.length > 0 && (
                  <div className="info_seasonsCont commonCont_scrollbar">
                    {seasonsData?.episodes?.length > 0 ? (
                      <>
                        {seasonsData?.episodes?.map((s, i) => (
                          <div key={i} onClick={onEpisodeClick}>
                            <img src={s.image_url} alt={s.name} />
                            <p>{s.name}</p>
                            <div>
                              <div
                                className="info_seasonsCont_editBtn"
                                onClick={(e) => onEpisodeUpdate(e, s)}
                              >
                                <FontAwesomeIcon icon={faPencilAlt} />
                              </div>

                              <div className="info_seasonsCont_deleteBtn">
                                {s.mid === deleteEpisodeNo &&
                                loadingDeleteEpisode ? (
                                  <CircularProgress
                                    size={14}
                                    style={{ color: "#fff" }}
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    onClick={(e) => onEpisodeDelete(e, s)}
                                    icon={faTrash}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <h4>No Episodes found!!!</h4>
                    )}
                  </div>
                )}
              </div>
            ) : params.id[0] === "m" ? null : (
              <h4>No Seasons & Episodes found!!!</h4>
            )}

            <div className="flex w-full ">
              {!data?.trending ? (
                <button
                  className=" px-5 bg-green-600 rounded-lg text-white py-2 flex justify-center items-center"
                  onClick={() => {
                    data.wsid ? setTrendindWS() : setTrendindMovie();
                  }}
                >
                  {tLoading ? (
                    <CircularProgress size={20} style={{ color: "#fff" }} />
                  ) : (
                    "ADD TO TRENDING"
                  )}
                </button>
              ) : (
                <button
                  className=" px-5 bg-red-600 rounded-lg text-white py-2 flex justify-center items-center"
                  onClick={() => {
                    data.wsid ? setTrendindWS() : setTrendindMovie();
                  }}
                >
                  {tLoading ? (
                    <CircularProgress size={20} style={{ color: "#fff" }} />
                  ) : (
                    "REMOVE FROM TRENDING"
                  )}
                </button>
              )}

              <button className="info_del" onClick={onDelete}>
                {delLoading ? (
                  <CircularProgress size={20} style={{ color: "#fff" }} />
                ) : (
                  "DELETE"
                )}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default MovieSeriesInfo;
