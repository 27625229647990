import React from 'react'
import ReactPlayer from 'react-player/lazy'
import './CustomVideoPlayer.css'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'

function CustomVideoPlayer({ previewImg, url }) {
    return (
        <div className='customVideoPlayer_container'>
            <ReactPlayer
                url={url}
                controls={true}
                pip={true}
                playing={true}
                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                light={previewImg}
                playIcon={<FontAwesomeIcon icon={faPlayCircle} className='customVideoPlayer_playIcon' />}
            />
        </div>
    )
}

export default CustomVideoPlayer
