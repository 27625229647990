import React from 'react'
import './StandardTextField.css'

function StandardTextField({ placeholder, val, onChange, textArea, rows }) {
    return (
        <>
            {textArea ? (
                <textarea
                    rows={rows}
                    value={val}
                    onChange={onChange}
                    placeholder={placeholder}
                    className='standardTextField_input' />
            ) : (
                <input
                    value={val}
                    onChange={onChange}
                    placeholder={placeholder}
                    className='standardTextField_input'
                />
            )}
        </>
    )
}

export default StandardTextField
