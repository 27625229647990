import "./AllMoviesAndWebseries.css";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { auth } from "../../components/impExports";
// Components
import Sidebar from "../../components/Sidebar/Sidebar";
import Loading from "../../components/Loading/Loading";
// Icons
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faSearch } from '@fortawesome/free-solid-svg-icons'

function AllMoviesAndWebseries() {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [allData, setAllData] = useState([]);
  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    fetchAllData();
  }, []);

  const fetchAllData = async () => {
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_BASEURL}/admin/getAllData`, {}, { auth })
      .then((res) => {
        if (res.data.success) {
          setAllData([...res.data.Movies, ...res.data.Web_Series]);
          setSearchData([...res.data.Movies, ...res.data.Web_Series]);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err, "Error fetching Movies");
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  // console.log(searchData);
  return (
    <div className="allmw_container">
      <Sidebar />

      <div className="allmw_cont">
        {loading ? (
          <Loading />
        ) : (
          <>
            {/* <div className='allmw_searchCont'>
                            <input placeholder='Search' value={search} onChange={e => setSearch(e.target.value)} />
                            <FontAwesomeIcon icon={faSearch} className='allmw_searchCont_icon' />
                        </div> */}

            <div className="allmw_imgContainer">
              {searchData?.map((m) => (
                <Link
                  key={m.mid || m.wsid}
                  to={`/infomw/${m.mid ? "m" : "w"}${m.mid || m.wsid}`}
                >
                  <div className="allmw_imgCont">
                    <img src={m.image_url} alt={m.name} />
                    <p>{m.name}</p>
                  </div>
                </Link>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default AllMoviesAndWebseries;
