import React from 'react'
import './Statics.css'
import Sidebar from '../../components/Sidebar/Sidebar'
// MUi
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons'

function Statics() {

    const StatsCommonCard = ({ up, num, content }) => (
        <div className='statics_card' style={{ background: up ? '#F3FFF1' : '#FFF1F1' }} >
            <h2 className='statics_cardNo'>{num}</h2>
            <h5 className='statics_cardContent' >{content}</h5>
            <p className='statics_cardPercent' style={{ color: up ? '#4AE79B' : '#E66D98' }} >
                <FontAwesomeIcon className='statics_cardIcon' icon={up ? faArrowUp : faArrowDown} />
                15.6% MORE
            </p>
        </div>
    )

    return (
        <div className='statics_container'>
            <Sidebar />

            <div className='statics_cont'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h6 style={{ fontSize: 22, fontWeight: 500, flex: .25, minWidth: 237 }} >Show Statistics for</h6>
                    <FormControl fullWidth variant="standard" className='statics_select' >
                        <Select defaultValue={'week'}>
                            <MenuItem value={'week'}>This Week</MenuItem>
                            <MenuItem value={'month'}>This Month</MenuItem>
                            <MenuItem value={'year'}>This Year</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div className='statics_cardContainer'>
                    <StatsCommonCard up num='23' content='Subscribers this week' />
                    <StatsCommonCard num='14' content='Movies added this week' />

                    <StatsCommonCard num='15' content='New Login IDs' />
                    <StatsCommonCard up num='13,539' content='Profit this week' />
                </div>
            </div>
        </div>
    )
}

export default Statics
