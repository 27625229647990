import React from "react";
import Logo from "../img/logo.svg";
import axios from "axios";

import { useSnackbar } from "notistack";
import Loading from "../components/Loading/Loading";

const Login = () => {
  const [email, setMail] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [password, setPassword] = React.useState();
  const { enqueueSnackbar } = useSnackbar();

  const login = async () => {

    setIsLoading(true);
    await axios
      .post(`${process.env.REACT_APP_BASEURL}/admin/login`, {
        username: email,
        password: password,
      })
      .then((res) => {
        if (res.data.Success) {
          localStorage.setItem(
            "token",
            btoa(
              process.env.REACT_APP_USERNAME +
                ":" +
                process.env.REACT_APP_PASSWORD
            )
          );
        enqueueSnackbar(`Login Successfully`, {
            variant: "success",
          });
          window.location.reload();
        } else {
        enqueueSnackbar(`Username or password incorrect`, {
          variant: "error",
        });
        }
      })
      .catch((e) => {
        enqueueSnackbar(`Something went wrong`, {
            variant: "error",
          });
      })
      .finally(() => setIsLoading(false));
  };

  return isLoading ? (
    <div className="h-full flex justify-center items-center">
      <Loading />
    </div>
  ) : (
    <div className=" w-full h-screen flex justify-center items-center">
      <div className="flex flex-col">
        <div className="flex items-center justify-center">
          <img src={Logo} width={150} />
        </div>

        <div className="flex bg-gray-100 rounded-md p-2 mt-3">
          <input
            id="email"
            name="email"
            type="email"
            onChange={(e) => setMail(e.target.value)}
            placeholder="Email"
            required
            className="p-0 bg-transparent border-none px-2 focus:border-none outline-0 "
            style={{ outline: "none", border: "none" }}
          />
        </div>
        <div className="flex bg-gray-100 rounded-md p-2 mt-3">
          <input
            id="password"
            name="password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
            className="p-0 bg-transparent border-none px-2 focus:border-none outline-0 "
            style={{ outline: "none", border: "none" }}
          />
        </div>
        <button
          type="submit"
          onClick={login}
          className="w-full mt-2 bg-gradient-to-r from-black to-blue-300 flex justify-center py-2 px-4 border-none rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-offset-2 "
        >
          Sign in
        </button>
      </div>
    </div>
  );
};

export default Login;
