import React from "react";
import "./Sidebar.css";
import { Link, useLocation } from "react-router-dom";
// Imgs
import logo from "../../img/logo.svg";
import addMovie from "../../img/addMovie.svg";
import subscribe from "../../img/subscribe.svg";
import statics from "../../img/statics.svg";
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import { AiOutlineLogout } from "react-icons/ai";

function Sidebar() {
  return (
    <div className="sidebar">
      <img src={logo} alt="python7" className="sidebar_logo" />

      <div>
        <SidebarOption
          img={addMovie}
          alt="add Movie"
          text={"Add a Movie"}
          link="/"
        />

        <SidebarOption
          img={subscribe}
          alt="Subscribers"
          text={"Subscribers"}
          link="/subscribers"
        />

        <SidebarOption
          img={statics}
          alt="Statistics"
          text={"Statistics"}
          link="/statics"
        />

        <SidebarOption
          icon={faVideo}
          text="All Movies & Webseries"
          link="/allmw"
        />

        <div onClick={() => {
            localStorage.removeItem('token')
            window.location.reload()
        }} className="sidebar_option">
          <AiOutlineLogout fontSize={35} />
          <p>Logout</p>
        </div>

        {/* <SidebarOption icon={faCog} text={'Settings'} /> */}
      </div>
    </div>
  );
}

export default Sidebar;

const SidebarOption = ({ img, alt, text, link, icon, onClick = null }) => {
  const loc = useLocation();

  return (
    <Link to={link || "/"}>
      <div
        className="sidebar_option"
        style={{ background: loc.pathname === link ? "lightgray" : "#fff" }}
      >
        {img ? (
          <img src={img} alt={alt} className="sidebar_optionIcon" />
        ) : (
          <FontAwesomeIcon style={{ fontSize: 35 }} icon={icon} />
        )}
        <p>{text}</p>
      </div>
    </Link>
  );
};
