import "./App.css";
import bg from "./img/background.jpg";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate
} from "react-router-dom";
// Pages
import AddMovie from "./Pages/AddMovie/AddMovie";
import Subscribers from "./Pages/Subscribers/Subscribers";
import Statics from "./Pages/Statics/Statics";
import AllMoviesAndWebseries from "./Pages/AllMoviesAndWebseries/AllMoviesAndWebseries";
import MovieSeriesInfo from "./Pages/MovieSeriesInfo/MovieSeriesInfo";
import Login from './Pages/Login.jsx'


const Home = () => (
  <div
    className="app_cont"
    style={{
      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${bg}) no-repeat center`,
    }}
  >
    <div className="app_header">
      <h4>Today, November 26 | FRIDAY</h4>
      <h6>WELCOME ADMIN</h6>
    </div>
    <Outlet />
  </div>
);

function App() {
  const isAuthenticated = localStorage.getItem("token")
    ? localStorage.getItem("token") ===
      btoa(
        process.env.REACT_APP_USERNAME + ":" + process.env.REACT_APP_PASSWORD
      ) ? true : false
    : false;
  return (
    <Router>
      <Routes>
        <Route
          path={"/"}
          element={ isAuthenticated ? <Home /> : <Navigate to="/login" />
          }
        >
          <Route path="subscribers" element={<Subscribers />} />
          <Route path="statics" element={<Statics />} />
          <Route path="allmw" element={<AllMoviesAndWebseries />} />
          <Route path="infomw/:id" element={<MovieSeriesInfo />} />
          <Route path="" element={<AddMovie />} />
        </Route>
        <Route
          path="/login"
          element={isAuthenticated ? <Navigate to="/" /> : <Login />}
        />
      </Routes>
    </Router>
  );
}

export default App;
