import React, { useEffect, useState } from 'react'
import './Subscribers.css'
import { auth } from '../../components/impExports'
import axios from 'axios'
// components
import Sidebar from '../../components/Sidebar/Sidebar'
import Loading from '../../components/Loading/Loading'
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
// Imgs
import logo from '../../img/logo.svg'

const Subscribers = () => {
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);

    useEffect(() => getAllUsers(), [])

    const getAllUsers = async () => {
        setLoading(true);
        await axios.post(`${process.env.REACT_APP_BASEURL}/admin/allusers/`, {}, { auth })
            .then(res => {
                if (res.data.success) {
                    setUsers(res.data.users)
                    setLoading(false)
                }
            })
            .catch(err => { console.log(err); setLoading(false) })
    }

    return (
        <div className='subscriber_container'>
            <Sidebar />

            <div className='subscriber_cont commonCont_scrollbar'>
                {loading ? <Loading /> : (
                    <>
                        {users?.map(u => (
                            <Card key={u.uid} membership={u?.membership?.membership} name={u?.full_name}
                                days_left={u?.membership?.days_left} />
                        ))}
                    </>
                )}
            </div>
        </div>
    )
}

export default Subscribers

const Card = (props) => (
    <div className='subscriber_card'>
        <div style={{ display: 'flex', flex: .4, alignItems: 'center' }} >
            <img src={logo} alt="python7" className='subscriber_img' />
            <h5 className='subscriber_name' >{props?.name}</h5>
        </div>

        <div className='subscriber_membershipDetails' >
            <h6 style={{ fontWeight: 500, fontSize: 18 }}>
                {props?.membership} Membership  |  Days Remaining : {props?.days_left}                
            </h6>
            {/* <div style={{ marginLeft: 20 }} >
                <FontAwesomeIcon style={{ fontSize: 18 }} icon={faArrowRight} />
            </div> */}
        </div>
    </div>
)