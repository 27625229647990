import React, { useState } from "react";
import "./AddMovie.css";
import {
  FormControlLabel,
  Switch,
  Radio,
  RadioGroup,
  Button,
  CircularProgress,
} from "@mui/material";
import { useSnackbar } from "notistack";
import axios from "axios";
// Components
import Sidebar from "../../components/Sidebar/Sidebar";
import StandardTextField from "../../components/StandardTextField/StandardTextField";

function AddMovie() {
  const [state, setState] = useState({
    name: "",
    genre: "",
    shortDesc: "",
    language: "",
    director: "",
    desc: "",
  });
  const [loading, setLoading] = useState(false);
  // Img
  const [uploadImg, setUploadImg] = useState(null);
  const [uploadImgObj, setUploadImgObj] = useState(null);
  // Video
  const [uploadVideo, setUploadVideo] = useState(null);
  const [uploadCount, setUploadCount] = useState(0)
  // Original
  const [checked, setChecked] = useState(false);
  // Radio
  const [radioVal, setRadioVal] = useState("Movie");
  // Snackbar
  const { enqueueSnackbar } = useSnackbar();
  const addMovieSeries = async () => {
    if (
      !state.name ||
      !state.genre ||
      !state.shortDesc ||
      !state.language ||
      !state.director ||
      !state.desc
    )
      return enqueueSnackbar(`Please Fill All The Details`, {
        variant: "error",
      });
    if (!uploadImg)
      return enqueueSnackbar(`Image Not Found`, { variant: "error" });
    let formData = new FormData();
    formData.append("name", state.name);
    formData.append("description", state.desc);
    formData.append("Language", state.language);
    formData.append("Director", state.director);
    formData.append("short_description", state.shortDesc);
    formData.append("Genre", state.genre);
    formData.append("orignal", checked ? 1 : 0);
    formData.append("image", uploadImg);

    if (radioVal === "Movie") {
      // Adding Movie
      if (!uploadVideo)
        return enqueueSnackbar(`Video Not Found`, { variant: "error" });
      setLoading(true);
      const video_filename = uploadVideo.name.replace(" ", "_");
      await axios
        .get(
          `${process.env.REACT_APP_BASEURL}/admin/aws_url/${video_filename}`,
          {
            headers: {
              Authorization:
                "Basic " +
                btoa(
                  process.env.REACT_APP_USERNAME +
                    ":" +
                    process.env.REACT_APP_PASSWORD
                ),
            },
          }
        )
        .then(
          async (res) =>
            await axios({
              url: res.data.url,
              method: "PUT",
              data: uploadVideo,
              headers: {
                "Content-Type": "multipart/form-data",
              },
              onUploadProgress: (data) => {
                setUploadCount(Math.round((100 * data.loaded) / data.total));
              },
            }).then(async (data) => {
              formData.append("q1080p", video_filename);
              await axios({
                method: "post",
                url: `${process.env.REACT_APP_BASEURL}/admin/addMovie/`,
                data: formData,
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization:
                    "Basic " +
                    btoa(
                      process.env.REACT_APP_USERNAME +
                        ":" +
                        process.env.REACT_APP_PASSWORD
                    ),
                },
              })
                .then((res) => {
                  if (res.data.success) {
                    resetAllValues();
                    return enqueueSnackbar(`Movie Added Successfully`, {
                      variant: "success",
                    });
                  }
                })
                .catch((err) => {
                  console.log(err);
                  setLoading(false);
                  return enqueueSnackbar(`Error Adding Movie`, {
                    variant: "error",
                  });
                });
            })
        );
    
    } else if (radioVal === "WebSeries") {
      // Adding Web Series
      formData.delete("q1080p");
      setLoading(true);
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASEURL}/admin/add_Web_series`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization:
            "Basic " +
            btoa(
              process.env.REACT_APP_USERNAME +
                ":" +
                process.env.REACT_APP_PASSWORD
            ),
        },
      })
        .then((res) => {
          if (res.data.success) {
            resetAllValues();
            return enqueueSnackbar(`Web Series Added Successfully`, {
              variant: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          return enqueueSnackbar(`Error Adding Web Series`, {
            variant: "error",
          });
        });
    }
  };

  const onAddImg = (e) => {
    setUploadImg(e.target.files[0]);
    setUploadImgObj(URL.createObjectURL(e.target.files[0]));
  };
  const onAddVideo = (e) => setUploadVideo(e.target.files[0]);
  const onChangeText = (e, name) =>
    setState({ ...state, [name]: e.target.value });
  const upload = (id) => document.getElementById(id).click();
  const resetAllValues = () => {
    setState({
      name: "",
      genre: "",
      shortDesc: "",
      language: "",
      director: "",
      desc: "",
    });
    setUploadImg(null);
    setUploadVideo(null);
    setUploadImgObj(null);
    setChecked(false);
    setLoading(false);
  };

  return (
    <div className="addMovie_container">
      <Sidebar />

      <div className="movie_cont">
        <RadioGroup
          row
          value={radioVal}
          onChange={(e) => {
            setRadioVal(e.target.value);
            setUploadVideo(null);
          }}
        >
          <FormControlLabel value="Movie" control={<Radio />} label="Movie" />
          <FormControlLabel
            value="WebSeries"
            control={<Radio />}
            label="Web Series"
          />
        </RadioGroup>
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: 20 }}
        >
          <div>
            {uploadImgObj ? (
              <div
                onClick={() => upload("movie_uploadFile")}
                className="movie_uploadImg"
                style={{
                  background: `url(${uploadImgObj}) no-repeat center/cover`,
                }}
              >
                <div>
                  {" "}
                  <p>Change Image</p>{" "}
                </div>
              </div>
            ) : (
              <button
                className="movie_uploadBtn"
                disabled={loading}
                onClick={() => upload("movie_uploadFile")}
              >
                Add Image
              </button>
            )}
            <input
              type="file"
              id="movie_uploadFile"
              accept="image/png, image/jpeg"
              onChange={onAddImg}
              style={{ display: "none" }}
            />
          </div>
          <div style={{ width: "100%" }}>
            <StandardTextField
              val={state.name}
              onChange={(e) => onChangeText(e, "name")}
              placeholder="Enter Movie Name"
            />
            <StandardTextField
              val={state.genre}
              onChange={(e) => onChangeText(e, "genre")}
              placeholder="Genre"
            />
          </div>
        </div>
        <StandardTextField
          val={state.shortDesc}
          onChange={(e) => onChangeText(e, "shortDesc")}
          placeholder="Year Release |  Web Series/Movie  | Duration  |  Maturity Rating"
        />

        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: 10, width: "100%" }}>
            <StandardTextField
              val={state.language}
              onChange={(e) => onChangeText(e, "language")}
              placeholder="Language"
            />
          </div>
          <div style={{ marginLeft: 10, width: "100%" }}>
            <StandardTextField
              val={state.director}
              onChange={(e) => onChangeText(e, "director")}
              placeholder="Director"
            />
          </div>
        </div>

        <StandardTextField
          val={state.desc}
          onChange={(e) => onChangeText(e, "desc")}
          placeholder="Director"
          rows="5"
          textArea
        />

        <div>
          <FormControlLabel
            className="movie_switch"
            control={
              <Switch
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
              />
            }
            label="Original"
          />
          {radioVal === "Movie" && (
            <>
              <Button
                onClick={() => upload("movie_uploadFileVideo")}
                disabled={loading}
                className="movie_videoUploadBtn"
              >
                {uploadVideo ? `${uploadVideo?.name} added` : "+ Video"}
              </Button>
              <input
                type="file"
                id="movie_uploadFileVideo"
                accept="video/*"
                onChange={onAddVideo}
                style={{ display: "none" }}
              />
            </>
          )}
        </div>

        <button
          className="movie_submitBtn"
          onClick={addMovieSeries}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={20} style={{ color: "#fff" }} />
          ) : (
            <> ADD {radioVal === "Movie" ? "MOVIE" : "WEB SERIES"} </>
          )}
          {loading ? `${uploadCount} %`: null}
        </button>
      </div>
    </div>
  );
}

export default AddMovie;
